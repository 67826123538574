export const SIGNIN = 'SIGNIN';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const SIGNOUT = 'SIGNOUT';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS';
export const FETCH_BRIEFS = 'FETCH_BRIEFS';
export const UPDATE_BRIEFS = 'UPDATE_BRIEFS';
export const SHOW_AUTH_LOADER = 'SHOW_AUTH_LOADER';
export const HIDE_AUTH_LOADER = 'HIDE_AUTH_LOADER';



export const SHOW_ERROR_SCREEN = 'SHOW_ERROR_SCREEN';



export const FETCHING_PAGE_DATA = 'FETCHING_PAGE_DATA';
export const FETCHING_PAGE_DATA_SUCCESS = 'FETCHING_PAGE_DATA_SUCCESS';



export const SHOW_NOTIFICATION_ALERT = 'SHOW_NOTIFICATION_ALERT';



export const FETCH_TOOL = 'FETCH_TOOL';
export const FETCH_TOOL_SUCCESS = 'FETCH_TOOL_SUCCESS';



export const FETCH_RECENT_PAYMENTS = 'FETCH_RECENT_PAYMENTS';
export const FETCH_RECENT_PAYMENTS_SUCCESS = 'FETCH_RECENT_PAYMENTS_SUCCESS';



export const FETCH_UNCOMPLETED_PAYMENTS = 'FETCH_UNCOMPLETED_PAYMENTS';
export const FETCH_UNCOMPLETED_PAYMENTS_SUCCESS = 'FETCH_UNCOMPLETED_PAYMENTS_SUCCESS';



export const FETCH_PAYMENT_CREATION_INFO = 'FETCH_PAYMENT_CREATION_INFO';
export const FETCH_PAYMENT_CREATION_INFO_SUCCESS = 'FETCH_PAYMENT_CREATION_INFO_SUCCESS';
export const GENERATE_TRANSACTION_ID = 'GENERATE_TRANSACTION_ID';
export const GENERATE_TRANSACTION_ID_SUCCESS = 'GENERATE_TRANSACTION_ID_SUCCESS';



export const FETCH_MY_PROFILE = 'FETCH_MY_PROFILE';
export const FETCH_MY_PROFILE_SUCCESS = 'FETCH_MY_PROFILE_SUCCESS';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';



export const SEND_CONTACT_US_MESSAGE = 'SEND_CONTACT_US_MESSAGE';
export const SEND_CONTACT_US_MESSAGE_SUCCESS = 'SEND_CONTACT_US_MESSAGE_SUCCESS';
export const SEND_CONTACT_US_MESSAGE_ERROR = 'SEND_CONTACT_US_MESSAGE_ERROR';



export const SHOW_MODAL_ALERT = 'SHOW_MODAL_ALERT';
export const HIDE_MODAL_ALERT = 'HIDE_MODAL_ALERT';