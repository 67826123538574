import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	container: {
		height: "100%",
		position: "relative",
		overflow: "hidden",
		'&>div': {
			margin: 0,
			backgroundRepeat: "no-repeat",
			backgroundColor: "#000",
			padding: "100px 0",
			position: "relative",
			minHeight: 0,
			color: "#eee!important",
			paddingTop: 220,
			paddingBottom: 170,
			backgroundImage: "url(/images/covers/cover_2.jpg)",
			backgroundPosition: "center center",
			backgroundSize: "cover",
			[theme.breakpoints.up(1024)]: {
				paddingTop: 230,
			},
		},
	},
	innerWrapper: {
		width: "90%",
		maxWidth: 500,
		padding: "0 20px",
		[theme.breakpoints.up(700)]: {
			maxWidth: 600,
		},
		[theme.breakpoints.up(1024)]: {
			maxWidth: 700,
			padding: "0 75px",
		},
	},
	h100: {
		height: "100%",
	},
	p0: {
		padding: 0,
	},
	m0: {
		margin: 0,
	},
	heading: {
		padding: 0,
		textRendering: "optimizeLegibility",
		letterSpacing: "normal",
		color: "#fff",
		fontSize: "2.2rem",
		lineHeight: "100%",
		textTransform: "none",
		margin: "0 0 20px 0",
		fontWeight: "normal!important",
		[theme.breakpoints.up(1024)]: {
			fontSize: "2.5rem",
		},
	},
	paragraphContainer: {
		marginBottom: "20px",
		textRendering: "optimizeLegibility",
		fontWeight: "normal!important",
		fontSize: 16,
		lineHeight: "1.4",
		[theme.breakpoints.up(1024)]: {
			fontSize: 18,
		},
	},
	paragraphWrapper: {
		textRendering: "optimizeLegibility",
		display: "inline",
		fontSize: "18px",
		fontWeight: "unset",
		lineHeight: 1.2,
		'& span': {
			color: "#fff",
		}
	},
}));

export default function Banner() {
	const classes = useStyles();
	return (
		<div className={classes.container}>
			<div>
				<div className={classes.innerWrapper}>
					<div className={clsx(classes.p0, classes.m0)}>
						<div className={clsx(classes.p0, classes.m0)}>
							<h1 className={classes.heading}>
								Access Premium Tools
							</h1>
							<p className={classes.paragraphContainer}>
								<span className={classes.paragraphWrapper}>
									<span>
										Get Access to our Best Premium Tools in one click! Install out extensions and get started
									</span>
								</span>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div >
	);
}