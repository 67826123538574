import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(() => ({
    root: {
        color: "hsla(0, 0%, 100%, .65)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        fontSize: "12px",
        flex: 1,
        maxWidth: "80%",
        textAlign: "center",
        "@media (max-height: 515px)": {
            position: "absolute",
            top: "100vh",
            height: "5vh",
            marginTop: "160px",
        },
    }
}))

const Footer = (props) => {
    const classes = useStyles()

    return (
        <footer className={classes.root}>
            <span>&copy; 2021 DataByCloud.com</span>
        </footer>
    )
}

Footer.propTypes = {
    className: PropTypes.string,
}

export default Footer
