import {
  FETCH_RECENT_PAYMENTS,
  FETCH_RECENT_PAYMENTS_SUCCESS,
  FETCH_UNCOMPLETED_PAYMENTS,
  FETCH_UNCOMPLETED_PAYMENTS_SUCCESS,
  FETCH_PAYMENT_CREATION_INFO,
  FETCH_PAYMENT_CREATION_INFO_SUCCESS,
  GENERATE_TRANSACTION_ID,
  GENERATE_TRANSACTION_ID_SUCCESS,
} from "constants/ActionTypes";

const INIT_STATE = {
  fetching: false,
  recent_fetching: false,
  uncompleted_fetching: false,
  TransactionIdReady: false,
  RecentPayments: { total: 1, perPage: 5, page: 1, lastPage: 1, data: [] },
  UncompletedPayments: { total: 1, perPage: 5, page: 1, lastPage: 1, data: [] },
  PaymentCreationInfo: { user: {}, tools: [], intervals: [] },
  TransactionId: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_RECENT_PAYMENTS: {
      return {
        ...state,
        recent_fetching: true
      }
    }
    case FETCH_UNCOMPLETED_PAYMENTS: {
      return {
        ...state,
        uncompleted_fetching: true
      }
    }
    case FETCH_PAYMENT_CREATION_INFO: {
      return {
        ...state,
        fetching: true
      }
    }
    case GENERATE_TRANSACTION_ID: {
      return {
        ...state,
        fetching: true,
        TransactionIdReady: false,
      }
    }
    case FETCH_RECENT_PAYMENTS_SUCCESS: {
      return {
        ...state,
        recent_fetching: false,
        RecentPayments: action.payload
      }
    }
    case FETCH_UNCOMPLETED_PAYMENTS_SUCCESS: {
      return {
        ...state,
        uncompleted_fetching: false,
        UncompletedPayments: action.payload
      }
    }
    case FETCH_PAYMENT_CREATION_INFO_SUCCESS: {
      return {
        ...state,
        fetching: false,
        PaymentCreationInfo: action.payload
      }
    }
    case GENERATE_TRANSACTION_ID_SUCCESS: {
      return {
        ...state,
        fetching: false,
        TransactionId: action.payload,
        TransactionIdReady: true,
      }
    }
    default:
      return {
        ...state,
        TransactionIdReady: false,
      };
  }
}
