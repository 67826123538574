import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Card, CardContent, Grid, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 20,
        color: '#3f4047',
        '& h3': {
            fontSize: '1.4rem',
            fontWeight: 500,
            marginBottom: 10,
        },
    },
    thingsToNote: {
        padding: theme.spacing(2),
        '& ul': {
            listStylePosition: 'outside',
            paddingLeft: '1.3rem',
            '& li': {
                fontSize: '1.05rem',
                fontWeight: 400,
                marginTop: '10px',
            }
        }
    },
    supportContact: {
        padding: theme.spacing(2),
        '& h3': {
            textAlign: 'center',
        },
        '& h4': {
            textAlign: 'center',
            fontWeight: 200,
            fontSize: ' 18px',
        },
    },
    mt1: {
        marginTop: theme.spacing(1),
    },
    iframe: {
        border: 'none',
        overflow: 'hidden',
        marginTop: 15,
        maxWidth: '100%'
    }
}))

const ThingsToNote = (props) => {
    const classes = useStyles()

    return (
        <Card className={classes.root}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item sm={7} xs={12}>
                        <div className={classes.thingsToNote}>
                            <Typography variant="h3">Things To Note:</Typography>
                            <ul>
                                <li>We provide safe access to premium tools with 95% uptime guaranteed.</li>
                                <li>To get access, please install both Databycloud Extensions in Chrome Webstore <a target="_blank" rel="noopener noreferrer" href="https://chrome.google.com/webstore/detail/databycloud-group-tools/mbjjeombjeklkbndcjgmfcdhfbjngcam">here</a> &amp; <a target="_blank" rel="noopener noreferrer" href="https://chrome.google.com/webstore/detail/databycloud-group-tools/makdmacamkifdldldlelollkkjnoiedg">here</a>
                                </li>
                                <li>Our service will only work with Google Chrome browser.</li>
                                <li>If any one or both plugins are not enabled then our service will not work.</li>
                                <li>After using our service you can disable plugins.</li>
                            </ul>
                        </div>
                    </Grid>
                    <Grid item sm={5} xs={12}>
                        <div className={classes.supportContact}>
                            <Typography variant="h3">
                                For any support contact:
                            </Typography>
                            <Typography variant="h4">
                                Email:
                            </Typography>
                            <Typography variant="h4" className={classes.mt1}>
                                <a href="mailto:support@databycloud.com">support@databycloud.com</a>
                            </Typography>
                            {/* <iframe
                                title="Facebook Frame"
                                src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FCloudFoundation-1005093483033181%2F&amp;tabs&amp;width=340&amp;height=70&amp;small_header=true&amp;adapt_container_width=true&amp;hide_cover=false&amp;show_facepile=false&amp;appId"
                                width="340"
                                height="70"
                                className={classes.iframe}
                                scrolling="no"
                                frameBorder="0"
                                allowtransparency="true"
                            ></iframe> */}
                        </div>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default ThingsToNote
