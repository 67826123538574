import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  SEND_CONTACT_US_MESSAGE,
} from "constants/ActionTypes";
import {
  SendContactUsMessageSuccess,
  SendContactUsMessageError,
} from "actions";
import request from 'utils/connector';

const sendContactUsMessage = async (obj) =>
  await request({
    path: '/contact-us?landngpage=true',
    method: 'POST',
    data: obj
  }).then(res => {
    if (res.error) {
      throw new Error(JSON.stringify(res.error))
    }
    return res.data
  });

function* SendContactUsMessage({ payload }) {
  try {
    yield call(sendContactUsMessage, payload);
    yield put(SendContactUsMessageSuccess());
  } catch (error) {
    yield put(SendContactUsMessageError());
  }
}

export function* SendContactUsMessageSaga() {
  yield takeEvery(SEND_CONTACT_US_MESSAGE, SendContactUsMessage);
}

export default function* rootSaga() {
  yield all([
    fork(SendContactUsMessageSaga),
  ]);
}