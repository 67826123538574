import React, { Fragment, Suspense } from 'react'
import { renderRoutes } from 'react-router-config'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { LinearProgress } from '@material-ui/core'

import { Footer, RightSection } from './components'

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: "#0d0f12",
    },
    left: {
        display: 'flex',
        flex: 'auto',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        [theme.breakpoints.up(768)]: {
            flex: 1,
        },
        [theme.breakpoints.up(1366)]: {
            flex: 4,
        },
    },



    spacer: {
        flex: 1,
        minHeight: "48px",
    },
    formContainer: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        flex: "auto",
        justifyContent: "center",
        paddingBottom: "12px",
    },
}))

const Auth = (props) => {
    const { route } = props

    const classes = useStyles()

    return (
        <Fragment>
            <div className={classes.container}>
                <div className={classes.left}>
                    <div className={classes.spacer}></div>
                    <div className={classes.formContainer}>
                        <Suspense fallback={<LinearProgress />}>
                            {renderRoutes(route.routes)}
                        </Suspense>
                    </div>
                    <Footer />
                </div>
                <RightSection />
            </div>
        </Fragment>
    )
}

Auth.propTypes = {
    route: PropTypes.object,
}

export default Auth
