import React from 'react'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import MomentUtils from '@date-io/moment'
import { Provider as StoreProvider } from 'react-redux'
import { ThemeProvider } from '@material-ui/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { renderRoutes } from 'react-router-config'

import theme from './theme'
import { configureStore } from './store'
import routes from './routes'
import { ScrollReset, GoogleAnalytics, CookiesNotification, ModalAlert } from './components'
import './mixins/moment'
import './mixins/validate'
import './mixins/prismjs'
import './assets/scss/index.scss'
import NotificationAlert from 'utils/notificationAlert';
import SessionExpired from 'utils/SessionExpired';
import Websocket from 'utils/websocket';

const history = createBrowserHistory()
const store = configureStore()

const App = () => {
    return (
        <StoreProvider store={store}>
            <ThemeProvider theme={theme}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <Router history={history}>
                        <ScrollReset />
                        <GoogleAnalytics />
                        <CookiesNotification />
                        <ModalAlert />
                        <SessionExpired />
                        <Websocket />
                        {renderRoutes(routes)}
                    </Router>
                    <NotificationAlert />
                </MuiPickersUtilsProvider>
            </ThemeProvider>
        </StoreProvider>
    )
}

export default App;
