import Cookies from 'universal-cookie';

const getApiToken = () => {
    const cookies = new Cookies();
    return cookies.get('__session');
};

const NavigateToLogin = () => {
    return new Promise((resolve, reject) => {
        window.onhashchange = resolve;
        window.location.href = "/auth/login"
    })
}

async function custom_fetch(options) {
    let { base_url, path, query, method, data } = options;
    let fd = new FormData();
    if (!base_url) base_url = process.env.REACT_APP_API_URL + '/api/v1'

    if (data) {
        const neat = {};

        Object.keys(data).forEach(key => {
            let value = data[key];

            // format arrays correctly before sending
            if (value && value.constructor === Array) {
                value.forEach((e, index) => {
                    neat[`${key}[${index}]`] = e;
                })
                return
            }

            neat[key] = value;
        })

        Object.keys(neat).map(e => fd.append(e, neat[e]))
    }

    let headers = new Headers();
    let token = getApiToken();
    headers.append("Authorization", token ? `Bearer ${token}` : '');

    let settings = {
        method: method,
        body: fd,
        redirect: 'follow',
        headers: headers
    };

    if (!data) delete settings.body;

    let response = await fetch(base_url + path + (query || ''), settings)
        .then(async response => {
            if (response.ok) {
                const json = await response.json();
                if (json.success === false && json.error && json.error.authentication && token) {
                    // window.location.href = "/auth/login"
                    await NavigateToLogin();
                }
                return json;
            } else {
                throw new Error('Something went wrong');
            }
        }).catch(e => ({
            error: { message: "A technical error occured" },
            success: false,
            meta: { status: 500 }
        }));

    // update cookie expiry to 30 minutes
    if (token) {
        const cookies = new Cookies();
        cookies.set('__session', token, { path: "/", expires: new Date(Date.now() + (1000 * 60 * 60)) });
    }

    return response;
}

export default custom_fetch;