import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  FETCH_MY_PROFILE,
  UPDATE_PROFILE,
  UPDATE_PASSWORD
} from "constants/ActionTypes";
import {
  FetchMyProfileSuccess,
  UpdateProfileSuccess,
  UpdatePasswordSuccess,
  ShowNotificationAlert,
  FetchingPageDataSuccess,
  FetchingPageData,
  ShowErrorScreen,
  UpdateBriefs
} from "actions";
import request from 'utils/connector';

const fetchMyProfile = async () =>
  await request({
    path: '/profile',
    method: 'GET'
  }).then(res => {
    if (res.error) {
      throw new Error(JSON.stringify(res.error))
    }
    return res.data
  });

const updateProfile = async (profile) =>
  await request({
    path: '/profile',
    method: 'POST',
    data: profile
  }).then(res => {
    if (res.error) {
      throw new Error(JSON.stringify(res.error))
    }
    return res.data
  });

const updatePassword = async (obj) =>
  await request({
    path: '/profile/password',
    method: 'POST',
    data: obj
  }).then(res => {
    if (res.error) {
      throw new Error(JSON.stringify(res.error))
    }
    return res.data
  });

function* FetchMyProfile({ vitalForPage }) {
  try {
    if (vitalForPage) yield put(FetchingPageData());

    const profile = yield call(fetchMyProfile);
    yield put(FetchMyProfileSuccess(profile));

    if (vitalForPage) yield put(FetchingPageDataSuccess());
  } catch (error) {
    yield put(ShowNotificationAlert({ type: "error", message: JSON.parse(error.message).message }));
    yield put(ShowErrorScreen());
  }
}

function* UpdateProfile({ payload }) {
  try {
    const brief_info = yield call(updateProfile, payload);
    yield put(UpdateBriefs(brief_info));
    yield put(UpdateProfileSuccess());
  } catch (error) {
    yield put(ShowNotificationAlert({ type: "error", message: JSON.parse(error.message).message }));
  }
}

function* UpdatePassword({ payload }) {
  try {
    yield call(updatePassword, payload);
    yield put(UpdatePasswordSuccess());
  } catch (error) {
    yield put(ShowNotificationAlert({ type: "error", message: JSON.parse(error.message).message }));
  }
}

export function* FetchMyProfileSaga() {
  yield takeEvery(FETCH_MY_PROFILE, FetchMyProfile);
}

export function* UpdateProfileSaga() {
  yield takeEvery(UPDATE_PROFILE, UpdateProfile);
}

export function* UpdatePasswordSaga() {
  yield takeEvery(UPDATE_PASSWORD, UpdatePassword);
}

export default function* rootSaga() {
  yield all([
    fork(FetchMyProfileSaga),
    fork(UpdateProfileSaga),
    fork(UpdatePasswordSaga)
  ]);
}