import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
	navBar: {
		display: "flex",
		alignItems: "center",
		position: "fixed",
		left: 0,
		right: 0,
		top: 0,
		padding: "0 20px",
		zIndex: 9999,
		height: 80,
		transition: "background .2s ease-in-out",
		[theme.breakpoints.up(1024)]: {
			height: 90,
		},
	},
	scrolledNavBar: {
		background: "#ffffff",
		boxShadow: "0 10px 20px 0 rgba(0, 0, 0, .2)",
	},
	navBarInner: {
		margin: "0px auto",
		padding: 0,
		display: "flex",
		alignItems: "center",
		width: "100%",
		maxWidth: 1200,
		zIndex: 9998,
		justifyContent: "space-between",
	},
	navBarBrand: {
		display: "flex",
		width: 200,
	},
	navBarBrandLink: {
		textDecoration: "none",
		width: 180,
		height: 40,
		backgroundImage: 'url(/images/logos/logo.png)',
		backgroundRepeat: "no-repeat",
		backgroundPosition: "left center",
		position: "relative",
		display: "inline-block",
		backgroundSize: "90% auto",
		[theme.breakpoints.up(1024)]: {
			backgroundSize: "100% auto",
		},
	},
	scrolledNavBarBrandLink: {
		backgroundImage: 'url(/images/logos/logo-2.png)!important',
	},
	navBarLinks: {
		display: "flex",
		[theme.breakpoints.up(1024)]: {
			justifyContent: "space-between",
		},
	},
	navBarLinkList: {
		display: "flex",
		[theme.breakpoints.up(1024)]: {
			justifyContent: "space-between",
		},
	},
	navBarLinkListItem: {
		listStyle: "none",
		margin: 0,
		padding: 0,
		display: "flex",
		position: "relative",
	},
	navBarLoginLink: {
		lineHeight: 1.1,
		borderRadius: 2,
		textAlign: "center",
		transition: "box-shadow .3s ease-in-out",
		margin: 0,
		letterSpacing: 1,
		textTransform: "uppercase",
		background: "0 0",
		border: "1px solid #fff",
		color: "#fff",
		fontWeight: 700,
		position: "relative",
		textDecoration: "none",
		fontSize: 12,
		padding: "13px 25px",
		display: "flex",
	},
	scrolledNavBarLoginLink: {
		color: "#253267!important",
		borderColor: "#253267"
	}
}));

export default function Header() {
	const classes = useStyles();
	const [scrollOffset, setScrollOffset] = useState(0);

	useEffect(() => {
		function HandleSroll() {
			setScrollOffset(window.pageYOffset);
		}

		window.addEventListener('scroll', HandleSroll);
		return () => window.removeEventListener('scroll', HandleSroll);
	}, [])

	const naBarClassName = clsx({
		[classes.navBar]: true,
		[classes.scrolledNavBar]: scrollOffset > 0
	})

	const navBarLoginLinkClassName = clsx({
		[classes.navBarLoginLink]: true,
		[classes.scrolledNavBarLoginLink]: scrollOffset > 0
	})

	const navBarBrandLinkClassName = clsx({
		[classes.navBarBrandLink]: true,
		[classes.scrolledNavBarBrandLink]: scrollOffset > 0
	})

	return (
		<nav className={naBarClassName}>
			<div className={classes.navBarInner}>
				<div className={classes.navBarBrand}>
					<Link href="#" className={navBarBrandLinkClassName} />
				</div>
				<div className={classes.navBarLinks}>
					<ul className={classes.navBarLinkList}>
						<li className={classes.navBarLinkListItem}>
							<Link to="/dashboard" className={navBarLoginLinkClassName}>Sign In</Link>
						</li>
					</ul>
				</div>
			</div>
		</nav>
	);
}