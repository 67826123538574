import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  FETCH_RECENT_PAYMENTS,
  FETCH_UNCOMPLETED_PAYMENTS,
  FETCH_PAYMENT_CREATION_INFO,
  GENERATE_TRANSACTION_ID,
} from "constants/ActionTypes";
import {
  FetchRecentPaymentsSuccess,
  FetchUncompletedPaymentsSuccess,
  FetchPaymentCreationInfoSuccess,
  GenerateTransactionIdSuccess,
  ShowNotificationAlert,
  FetchingPageDataSuccess,
  FetchingPageData,
  ShowErrorScreen
} from "actions";
import request from 'utils/connector';

const fetchRecentPayments = async (queryString) =>
  await request({
    path: '/payments?' + queryString,
    method: 'GET'
  }).then(res => {
    if (res.error) {
      throw new Error(JSON.stringify(res.error))
    }
    return res.data
  });

const fetchUncompletedPayments = async (queryString) =>
  await request({
    path: '/payments?' + queryString,
    method: 'GET'
  }).then(res => {
    if (res.error) {
      throw new Error(JSON.stringify(res.error))
    }
    return res.data
  });

const fetchPaymentCreationInfo = async () =>
  await request({
    path: '/payments/new',
    method: 'GET'
  }).then(res => {
    if (res.error) {
      throw new Error(JSON.stringify(res.error))
    }
    return res.data
  });

const generateTransactionId = async (expiry, selectedTools) =>
  await request({
    path: '/payments/new',
    method: 'POST',
    data: { expiry, selectedTools }
  }).then(res => {
    if (res.error) {
      throw new Error(JSON.stringify(res.error))
    }
    return res.data
  });

function* FetchRecentPayments({ payload }) {
  const { page, searchString, rowsPerPage } = payload;

  const queryString = [
    "status=completed",
    "page=" + (page ? page : ""),
    "search=" + (searchString ? searchString : ""),
    "count=" + (rowsPerPage ? rowsPerPage : "")
  ].join("&");

  try {
    const payments = yield call(fetchRecentPayments, queryString);
    yield put(FetchRecentPaymentsSuccess(payments));
  } catch (error) {
    yield put(ShowNotificationAlert({ type: "error", message: JSON.parse(error.message).message }));
  }
}

function* FetchUncompletedPayments({ payload }) {
  const { page, searchString, rowsPerPage } = payload;

  const queryString = [
    "status=uncompleted",
    "page=" + (page ? page : ""),
    "search=" + (searchString ? searchString : ""),
    "count=" + (rowsPerPage ? rowsPerPage : "")
  ].join("&");

  try {
    const payments = yield call(fetchUncompletedPayments, queryString);
    yield put(FetchUncompletedPaymentsSuccess(payments));
  } catch (error) {
    yield put(ShowNotificationAlert({ type: "error", message: JSON.parse(error.message).message }));
  }
}

function* FetchPaymentCreationInfo({ vitalForPage }) {
  try {
    if (vitalForPage) yield put(FetchingPageData());

    const obj = yield call(fetchPaymentCreationInfo);
    yield put(FetchPaymentCreationInfoSuccess(obj));

    if (vitalForPage) yield put(FetchingPageDataSuccess());
  } catch (error) {
    yield put(ShowNotificationAlert({ type: "error", message: JSON.parse(error.message).message }));
    yield put(ShowErrorScreen());
  }
}

function* GenerateTransactionId({ payload }) {
  try {
    const { expiry, selectedTools } = payload;
    const obj = yield call(generateTransactionId, expiry, selectedTools);
    yield put(GenerateTransactionIdSuccess(obj));
  } catch (error) {
    yield put(ShowNotificationAlert({ type: "error", message: JSON.parse(error.message).message }));
  }
}

export function* FetchRecentPaymentsSaga() {
  yield takeEvery(FETCH_RECENT_PAYMENTS, FetchRecentPayments);
}

export function* FetchUncompletedPaymentsSaga() {
  yield takeEvery(FETCH_UNCOMPLETED_PAYMENTS, FetchUncompletedPayments);
}

export function* FetchPaymentCreationInfoSaga() {
  yield takeEvery(FETCH_PAYMENT_CREATION_INFO, FetchPaymentCreationInfo);
}

export function* GenerateTransactionIdSaga() {
  yield takeEvery(GENERATE_TRANSACTION_ID, GenerateTransactionId);
}

export default function* rootSaga() {
  yield all([
    fork(FetchRecentPaymentsSaga),
    fork(FetchUncompletedPaymentsSaga),
    fork(FetchPaymentCreationInfoSaga),
    fork(GenerateTransactionIdSaga)
  ]);
}