import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  HeadsetMic as HeadsetMicIcon,
  NetworkCheck as NetworkCheckIcon,
  Telegram as TelegramIcon
} from '@material-ui/icons';

import InfoArea from "./InfoArea";

import { Grid } from "@material-ui/core";

const useStyles = makeStyles({
  section: {
    padding: "70px 0 100px",
    textAlign: "center",
  },
  grid: {
    marginRight: "-15px",
    marginLeft: "-15px",
    width: "auto"
  },
  gridItem: {
    position: "relative",
    width: "100%",
    minHeight: "1px",
    paddingRight: "15px",
    paddingLeft: "15px",
    flexBasis: "auto"
  },
  title: {
    color: "#3C4858",
    margin: "1.75rem 0 0.875rem",
    textDecoration: "none",
    fontWeight: "700",
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    fontSize: '1.5rem',
    textTransform: 'uppercase'
  },
  description: {
    color: "#999"
  }
});

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <Grid container className={classes.grid} justify="center">
        <Grid item className={classes.gridItem} xs={12} sm={12} md={8}>
          <h2 className={classes.title}>How It Works</h2>
        </Grid>
      </Grid>
      <div>
        <Grid container className={classes.grid} justify="center">
          <Grid item className={classes.gridItem} xs={12} sm={12} md={4}>
            <InfoArea
              title="95% Access Guarantee!"
              description="Our servers have 95% uptime which makes you have access to your tools whenever you need them. You rarely get disappointed"
              icon={NetworkCheckIcon}
              type="uptime"
              vertical
            />
          </Grid>
          <Grid item className={classes.gridItem} xs={12} sm={12} md={4}>
            <InfoArea
              title="Instant Tool Access!"
              description="Instant access is granted to relevant tools immediately you finalize your subscription to the tool(s) and have our extensions installed."
              icon={TelegramIcon}
              type="instantAccess"
              vertical
            />
          </Grid>
          <Grid item className={classes.gridItem} xs={12} sm={12} md={4}>
            <InfoArea
              title="24/7 Customer Support!"
              description="Our support team is available to answer your questions and take you through the technical difficulties you might face (which is very unlikely) "
              icon={HeadsetMicIcon}
              type="support"
              vertical
            />
          </Grid>
        </Grid>
      </div>
    </div >
  );
}
