import { combineReducers } from 'redux'

import authReducer from './Auth'
import contactUsReducer from './ContactUs'
import errorScreenReducer from './ErrorScreen'
import modalAlertReducer from './ModalAlert'
import notificationAlertReducer from './NotificationAlert'
import pageReducer from './Page'
import paymentReducer from './Payment'
import profileReducer from './Profile'
import toolReducer from './Tool'

const rootReducer = combineReducers({
    Auth: authReducer,
    ContactUs: contactUsReducer,
    ErrorScreen: errorScreenReducer,
    ModalAlert: modalAlertReducer,
    NotificationAlert: notificationAlertReducer,
    Page: pageReducer,
    Payment: paymentReducer,
    Profile: profileReducer,
    Tool: toolReducer,
})

export default rootReducer
