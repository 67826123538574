import {
  SEND_CONTACT_US_MESSAGE,
  SEND_CONTACT_US_MESSAGE_SUCCESS,
  SEND_CONTACT_US_MESSAGE_ERROR,
} from 'constants/ActionTypes';

export const SendContactUsMessage = (obj) => {
  return {
    type: SEND_CONTACT_US_MESSAGE,
    payload: obj
  };
};

export const SendContactUsMessageSuccess = () => {
  return {
    type: SEND_CONTACT_US_MESSAGE_SUCCESS
  };
};

export const SendContactUsMessageError = () => {
  return {
    type: SEND_CONTACT_US_MESSAGE_ERROR
  };
};