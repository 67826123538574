import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        display: 'none',
        [theme.breakpoints.up(768)]: {
            flex: 1,
            display: 'block'
        },
        [theme.breakpoints.up(1366)]: {
            flex: 6,
            display: 'block'
        },
    },
    banner: {
        backgroundImage: "url(/images/logos/login-banner-min.jpg)",
        backgroundColor: "black",
        backgroundPosition: "right center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        justifyContent: "flex-start",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        height: "100vh",
    },
}))

const RightSection = () => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <div className={classes.banner}>
            </div>
        </div>
    )
}

RightSection.propTypes = {
    className: PropTypes.string,
}

export default RightSection
