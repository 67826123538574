import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  infoArea: {
    maxWidth: "360px",
    margin: "0 auto",
    padding: "0px"
  },
  iconWrapper: {
    float: "left",
    marginTop: "24px",
    marginRight: "10px"
  },
  uptime: {
    color: "#70A5C4",
  },
  instantAccess: {
    color: "#3541fca1",
  },
  support: {
    color: "#ff267cb3",
  },
  icon: {
    width: "36px",
    height: "36px"
  },
  descriptionWrapper: {
    color: "#999999",
    overflow: "hidden"
  },
  title: {
    color: "#999999",
    overflow: "hidden",
    marginTop: "0px",
    fontSize: "1.3rem",
    fontWeight: 400,
    marginBottom: 10
  },
  description: {
    color: "#999999",
    overflow: "hidden",
    marginTop: "0px",
    fontSize: "14px"
  },
  iconWrapperVertical: {
    float: "none"
  },
  iconVertical: {
    width: "61px",
    height: "61px"
  }
});

export default function InfoArea(props) {
  const classes = useStyles();
  const { title, description, type, vertical } = props;
  const iconWrapper = classNames({
    [classes.iconWrapper]: true,
    [classes[type]]: true,
    [classes.iconWrapperVertical]: vertical
  });
  const iconClasses = classNames({
    [classes.icon]: true,
    [classes.iconVertical]: vertical
  });
  return (
    <div className={classes.infoArea}>
      <div className={iconWrapper}>
        <props.icon className={iconClasses} />
      </div>
      <div className={classes.descriptionWrapper}>
        <h4 className={classes.title}>{title}</h4>
        <p className={classes.description}>{description}</p>
      </div>
    </div>
  );
}