import DashboardIcon from '@material-ui/icons/DashboardOutlined'
import SettingsIcon from '@material-ui/icons/SettingsOutlined'
import AssignmentIcon from '@material-ui/icons/AssignmentOutlined'

export default [
    {
        title: 'Pages',
        pages: [
            {
                title: 'Dashboard',
                href: '/dashboard',
                icon: DashboardIcon
            },
            {
                title: 'Manage Payments',
                href: '/payments',
                icon: AssignmentIcon
            },
            {
                title: 'My Profile',
                href: '/profile',
                icon: SettingsIcon,
            },
        ],
    },
]
