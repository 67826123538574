import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Avatar } from '@material-ui/core'

const useStyles = makeStyles(() => ({
    image: {
        borderRadius: '50%'
    }
}))

const ToolImage = ({ tool }) => {
    const classes = useStyles()
    
    return (
        <Avatar
            alt={tool.name}
            src={tool.image || ""}
            width="40"
            height="40"
            className={classes.image}
        // component="img"
        />
    )
}

export default ToolImage
