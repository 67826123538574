import {
  FETCH_RECENT_PAYMENTS,
  FETCH_RECENT_PAYMENTS_SUCCESS,
  FETCH_UNCOMPLETED_PAYMENTS,
  FETCH_UNCOMPLETED_PAYMENTS_SUCCESS,
  FETCH_PAYMENT_CREATION_INFO,
  FETCH_PAYMENT_CREATION_INFO_SUCCESS,
  GENERATE_TRANSACTION_ID,
  GENERATE_TRANSACTION_ID_SUCCESS
} from 'constants/ActionTypes';

export const FetchRecentPayments = (page, searchString, rowsPerPage) => {
  return {
    type: FETCH_RECENT_PAYMENTS,
    payload: { page, searchString, rowsPerPage }
  };
};

export const FetchRecentPaymentsSuccess = (payments) => {
  return {
    type: FETCH_RECENT_PAYMENTS_SUCCESS,
    payload: payments
  };
};

export const FetchUncompletedPayments = (page, searchString, rowsPerPage) => {
  return {
    type: FETCH_UNCOMPLETED_PAYMENTS,
    payload: { page, searchString, rowsPerPage }
  };
};

export const FetchUncompletedPaymentsSuccess = (payments) => {
  return {
    type: FETCH_UNCOMPLETED_PAYMENTS_SUCCESS,
    payload: payments
  };
};

export const FetchPaymentCreationInfo = (vitalForPage = false) => {
  return {
    type: FETCH_PAYMENT_CREATION_INFO,
    vitalForPage: vitalForPage
  };
};

export const FetchPaymentCreationInfoSuccess = (obj) => {
  return {
    type: FETCH_PAYMENT_CREATION_INFO_SUCCESS,
    payload: obj
  };
};

export const GenerateTransactionId = (expiry, selectedTools) => {
  return {
    type: GENERATE_TRANSACTION_ID,
    payload: { expiry, selectedTools }
  };
};

export const GenerateTransactionIdSuccess = (obj) => {
  return {
    type: GENERATE_TRANSACTION_ID_SUCCESS,
    payload: obj
  };
};