import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  FETCH_TOOL
} from "constants/ActionTypes";
import {
  FetchToolSuccess,
  ShowNotificationAlert,
  ShowErrorScreen,
  FetchingPageData,
  FetchingPageDataSuccess
} from "actions";
import request from 'utils/connector';

const fetchTool = async (alias) =>
  await request({
    path: '/tool/' + alias,
    method: 'GET'
  }).then(res => {
    if (res.error) {
      throw new Error(JSON.stringify(res.error))
    }
    return res.data
  });

function* FetchTool({ payload, vitalForPage }) {
  const alias = payload;
  try {
    if (vitalForPage) yield put(FetchingPageData());

    const tool = yield call(fetchTool, alias);
    yield put(FetchToolSuccess(tool));

    if (vitalForPage) yield put(FetchingPageDataSuccess());
  } catch (error) {
    yield put(ShowNotificationAlert({ type: "error", message: JSON.parse(error.message).message }));
    yield put(ShowErrorScreen());
  }
}

export function* FetchToolSaga() {
  yield takeEvery(FETCH_TOOL, FetchTool);
}

export default function* rootSaga() {
  yield all([fork(FetchToolSaga)]);
}