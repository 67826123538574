import {
  FETCH_TOOL,
  FETCH_TOOL_SUCCESS
} from 'constants/ActionTypes';

export const FetchTool = (alias, vitalForPage) => {
  return {
    type: FETCH_TOOL,
    payload: alias,
    vitalForPage: vitalForPage
  };
};

export const FetchToolSuccess = (tool) => {
  return {
    type: FETCH_TOOL_SUCCESS,
    payload: tool
  };
};