import {
  SEND_CONTACT_US_MESSAGE,
  SEND_CONTACT_US_MESSAGE_SUCCESS,
  SEND_CONTACT_US_MESSAGE_ERROR
} from "constants/ActionTypes";

const INIT_STATE = {
  sending: false,
  success: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SEND_CONTACT_US_MESSAGE: {
      return {
        ...state,
        sending: true,
        success: null,
      }
    }
    case SEND_CONTACT_US_MESSAGE_SUCCESS: {
      return {
        ...state,
        sending: false,
        success: true,
      }
    }
    case SEND_CONTACT_US_MESSAGE_ERROR: {
      return {
        ...state,
        sending: false,
        success: false,
      }
    }
    default:
      return {
        ...state,
      };
  }
}
