import { all } from 'redux-saga/effects';
import AuthSagas from './Auth';
import ContactUsSagas from './ContactUs';
import PaymentSagas from './Payment';
import ProfileSagas from './Profile';
import ToolSagas from './Tool';

export default function* rootSaga() {
  yield all([
    AuthSagas(),
    ContactUsSagas(),
    ToolSagas(),
    PaymentSagas(),
    ProfileSagas(),
  ]);
}
