import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import {
    List,
    ListItem,
    ListItemText,
    ListSubheader,
} from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import useRouter from 'utils/useRouter'

const useStyles = makeStyles((theme) => ({
    root: {},
    listItem: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.background.default,
        },
    },
    arrowForwardIcon: {
        color: theme.palette.icon,
    },
    listSection: {
        backgroundColor: 'inherit',

    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    subheaderName: {
        fontSize: 12,
        borderBottom: '1px solid #eeeeee',
        textTransform: 'uppercase',
        lineHeight: 'initial',
        paddingTop: 12,
        paddingBottom: 12
    },
    listText: {
        '&>span': {
            fontSize: 14
        }
    }
}))

const ToolList = (props) => {
    let { tools, closePopOver, className, ...rest } = props;

    tools = [...tools]

    const classes = useStyles()

    const router = useRouter();

    const handleClick = (url) => {
        closePopOver();
        router.history.push(url)
    }

    return (
        <List
            {...rest}
            className={clsx(classes.root, className)}
            disablePadding
            subheader={<li />}
        >
            <li className={classes.listSection}>
                <ul className={classes.ul}>
                    <ListSubheader className={classes.subheaderName}>Access Tools</ListSubheader>

                    {tools.map((tool, i) => (
                        <ListItem
                            className={classes.listItem}
                            divider={i < tools.length - 1}
                            key={tool.id}
                            onClick={() => handleClick("/tool/" + tool.alias)}
                        >
                            <ListItemText
                                primary={tool.name}
                                primaryTypographyProps={{ variant: 'body1' }}
                                className={classes.listText}
                            />
                            <ArrowForwardIcon className={classes.arrowForwardIcon} />
                        </ListItem>
                    ))}
                </ul>
            </li>
        </List>
    )
}

ToolList.propTypes = {
    className: PropTypes.string,
    tools: PropTypes.array.isRequired,
}

export default ToolList
