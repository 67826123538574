/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react'
import { Redirect } from 'react-router-dom'

import AuthLayout from './layouts/Auth'
import ErrorLayout from './layouts/Error'
import DashboardLayout from './layouts/Dashboard'
import DashboardView from './views/Dashboard'
import LandingPage from './views/LandingPage'

const routes = [
    {
        path: '/',
        exact: true,
        component: LandingPage,
    },
    {
        path: '/auth',
        component: AuthLayout,
        routes: [
            {
                path: '/auth/login',
                exact: true,
                component: lazy(() => import('views/Login')),
            },
            {
                path: '/auth/reset-password',
                exact: true,
                component: lazy(() => import('views/ResetPassword')),
            },
            {
                component: () => <Redirect to="/errors/error-404" />,
            },
        ],
    },
    {
        path: '/errors',
        component: ErrorLayout,
        routes: [
            {
                path: '/errors/error-401',
                exact: true,
                component: lazy(() => import('views/Error401')),
            },
            {
                path: '/errors/error-404',
                exact: true,
                component: lazy(() => import('views/Error404')),
            },
            {
                path: '/errors/error-500',
                exact: true,
                component: lazy(() => import('views/Error500')),
            },
            {
                path: '/errors/access-denied',
                exact: true,
                component: lazy(() => import('views/ErrorAccessDenied')),
            },
            {
                component: () => <Redirect to="/errors/error-404" />,
            },
        ],
    },
    {
        route: '*',
        component: DashboardLayout,
        routes: [
            {
                path: '/tool/:alias',
                exact: true,
                component: lazy(() => import('views/Tool')),
            },
            {
                path: '/access-tool/:alias',
                exact: true,
                component: lazy(() => import('views/AccessTool')),
            },
            {
                path: '/access-rdp/:alias',
                exact: true,
                component: lazy(() => import('views/AccessTool')),
            },
            {
                path: '/payments',
                exact: true,
                component: lazy(() => import('views/Payments')),
            },
            {
                path: '/payments/new',
                exact: true,
                component: lazy(() => import('views/NewPayment')),
            },
            {
                path: '/dashboard',
                exact: true,
                component: DashboardView,
            },
            {
                path: '/profile',
                exact: true,
                component: lazy(() => import('views/Profile')),
            },
            {
                path: '/settings',
                exact: true,
                component: lazy(() => import('views/Settings')),
            },
            {
                path: '/settings/:tab',
                exact: true,
                component: lazy(() => import('views/Settings')),
            },
            {
                component: () => <Redirect to="/errors/error-404" />,
            },
        ],
    },
]

export default routes
