import {
  FETCH_TOOL,
  FETCH_TOOL_SUCCESS
} from "constants/ActionTypes";

const INIT_STATE = {
  fetching: false,
  tool: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_TOOL: {
      return {
        ...state,
        fetching: true,
        tool: null
      }
    }
    case FETCH_TOOL_SUCCESS: {
      return {
        ...state,
        fetching: false,
        tool: action.payload
      }
    }
    default:
      return {
        ...state
      };
  }
}
